<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create a Site</h4>
                        <form @submit.prevent="createSite">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Site Category</label>
                                        <multiselect  :preselect-first="true" v-model="form.site_category" @select="selected" :options="options2" :multiple="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Enrollment status</label>
                                        <date-picker
                                                v-model="form.enrollment_status"
                                                type="year"
                                                lang="en"
                                                placeholder="Enrolled since"
                                                :class="{ 'is-invalid': submitted && $v.form.enrollment_status.$error }"
                                        ></date-picker>
                                    </div>
                                    <div v-if="submitted && $v.form.enrollment_status.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.enrollment_status.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">

                                        <label for="validationCustom01">Site Name *</label>
                                        <input
                                                id="validationCustom01"
                                                v-model="form.site_name"
                                                type="text"
                                                class="form-control"
                                                placeholder="Site name"
                                                :class="{ 'is-invalid': submitted && error.site_name }"
                                        />
                                        <div v-if="submitted && error.site_name" class="invalid-feedback">
                                            <span v-if="error.site_name">This value is required.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Province/Region *</label>
                                        <multiselect v-model="form.region"
                                                     :options="regionData"
                                                     :multiple="false"
                                                     label="name"
                                                     placeholder="Select Province/Region"
                                                     :class="{ 'is-invalid': submitted && error.region }"
                                        >

                                        </multiselect>
                                    </div>
                                    <div v-if="submitted && error.region" class="invalid-feedback">
                                        <span v-if="error.region">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Location *</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="location"
                                                v-model="form.location"
                                                :class="{ 'is-invalid': submitted && $v.form.location.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.location.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.location.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>District</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="District"
                                                v-model="form.district"
                                                :class="{ 'is-invalid': submitted && $v.form.district.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.district.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.district.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <label>PTID</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" v-if="displayForm[0]">{{this.countries.country.country_prefix}}-EIDPOC-</span>
                                                <span class="input-group-text" v-if="displayForm[1]">{{this.countries.country.country_prefix}}-VL-</span>
                                            </div>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="PTID"
                                                    v-model="form.ptid"
                                                    :class="{ 'is-invalid': submitted && error.ptid }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Contact Person</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Contact Person"
                                                v-model="form.contact_person"
                                                :class="{ 'is-invalid': submitted && $v.form.contact_person.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.contact_person.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.contact_person.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Telephone</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Telephone"
                                                v-model="form.telephone"
                                                :class="{ 'is-invalid': submitted && $v.form.telephone.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.telephone.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.telephone.required">This value is required.</span>
                                        <span v-if="!$v.form.telephone.numeric">This value should be a valid email.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                v-model="form.email"
                                        />
                                        <div v-if="email ==1" >
                                            <span>This value is required.</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Facility e-mail</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Facility Email"
                                                v-model="form.facility_email"
                                                :class="{ 'is-invalid': submitted && $v.form.facility_email.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.facility_email.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.facility_email.required">This value is required.</span>
                                        <span v-if="!$v.form.facility_email.email">This value should be a valid email.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Type of facility </label>
                                        <multiselect v-model="form.type_facility" :options="options1" :multiple="false"></multiselect>
                                    </div>
                                    <div v-if="submitted && $v.form.type_facility.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.type_facility.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Plateform Type</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Plateform Type"
                                                v-model="form.platform_type"
                                                :class="{ 'is-invalid': submitted && $v.form.platform_type.$error }"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.platform_type.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.platform_type.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <textarea
                                                v-model="form.comments"
                                                class="form-control"
                                                :maxlength="225"
                                                rows="3"
                                                placeholder="Comment"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Head of testing site</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Head of testing site"
                                                v-model="form.head_of_testingsite"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Phone of head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Phone of head"
                                                v-model="form.phone_of_head"
                                        />
                                    </div>
                                    <div v-if="submitted && $v.form.phone_of_head.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.phone_of_head.required">This value is required.</span>
                                        <span v-if="!$v.form.phone_of_head.numeric">This value should be a valid email.</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Email of head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Email of head"
                                                v-model="form.email_of_head"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Head of facility</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Head of facility"
                                                v-model="form.head_of_facility"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Phone of facility head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Phone of head"
                                                v-model="form.phone_of_facility_head"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Email of facility head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Email of facility head"
                                                v-model="form.email_of_facility_head"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary">Create</button>
                        </form>
                    </div>
                </div>
                <div class="lodader__content" v-if="loader">
                    <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                </div>
                <!-- end card -->
            </div>
        </div>
    </layout>
</template>


<script>
        import Layout from "../../layouts/main";
        import PageHeader from "@/components/page-header";
        import appConfig from "@/app.config";
        import Multiselect from "vue-multiselect";
        import DatePicker from "vue2-datepicker";
        import {useVuelidate } from "@vuelidate/core";
       // Vue.use(Vuelidate)
        import {axios_get, axios_post} from "../../../helpers/helper";

        /**
         * Form validation component
         */
        export default {
            page: {
                title: "Create Site",
                meta: [{ name: "description", content: appConfig.description }]
            },
            components: { Layout, Multiselect,PageHeader, DatePicker },
            data() {
                return {
                    regionData:[],
                    country_id:null,
                    countries:null,
                    loader:false,
                    displayForm:[false,false],
                    form : {
                        ptid:null,
                        suffix:null,
                        site_name: null,
                        location: null,
                        contact_person: null,
                        email: null,
                        type_facility: null,
                        telephone: null,
                        region: null,
                        enrollment_status: null,
                        facility_email: null,
                        site_category: null,
                        comments: null,
                        head_of_testingsite: null,
                        head_of_facility: null,
                        phone_of_head: null,
                        email_of_head: null,
                        email_of_facility_head: null,
                        phone_of_facility_head: null,
                    },
                    email : 0,
                    error: {
                        ptid: false,
                        site_name: false,
                        location: false,
                        contact_person: false,
                        email: false,
                        type_facility: false,
                        telephone: false,
                        region: false,
                        enrollment_status: false,
                        facility_email: false,
                        site_category: false,
                    },
                    title: "Create Site",
                    items: [
                        {
                            text: "Site",
                            href: "/"
                        },
                        {
                            text: "Create Site",
                            active: true
                        }
                    ],
                    options: [
                        "Adamawa ",
                        "Centre",
                        "East",
                        "Far North",
                        "Littoral",
                        "North",
                        "North West",
                        "West",
                        "South",
                        "South West",
                    ],
                    options1: [
                        "Confessional ",
                        "Faith based",
                        "Parastatal",
                        "Public",
                        "NGO",
                    ],
                    options2: [
                        "EID",
                        "VL",
                    ],
                    prefix:"",
                    submitted: false,
                    submitform: false,
                    submit: false,
                    typesubmit: false,
                };

            },
            setup: () => ({ $v: useVuelidate() }),
            mounted() {
                this.countries=JSON.parse(localStorage.getItem('user'))
                console.log(this.countries)
                if(this.countries.role === 'SUPERADMIN'){
                    axios_get("/regions",{},(d)=>{
                        this.regionData=d.sort((a, b)=>{
                            if(a.name < b.name) return -1;
                            else return 1;
                        })
                        console.log(d)
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );
                }else {
                    this.country_id=this.countries.country_id
                    axios_get("/regions",{},(d)=>{
                            this.regionData=d.filter(e=>{return e.country_id==this.country_id})
                            console.log(d)
                            this.regionData=this.regionData.sort((a, b)=>{
                                if(a.name < b.name) return -1;
                                else return 1;
                            })
                        console.log(this.regionData)
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );
                }
                
            },
            methods: {
                selected(value2){
                    switch (value2) {
                        case 'EID':
                            this.displayForm=[true,false];
                            this.prefix=this.countries.country.country_prefix+"-EIDPOC- ";

                            break;
                        case 'VL':
                            this.displayForm=[false,true];
                            this.prefix=this.countries.country.country_prefix+"-VL- ";
                            break
                        default:
                            this.displayForm=[true,false];
                            this.prefix=this.countries.country.country_prefix+"-EIDPOC- "

                    }

                },
                // eslint-disable-next-line no-unused-vars
                formSubmit() {
                    this.submitted = true;
                    // stop here if form is invalid
                    this.$v.$touch();
                    console.log()
                },

                createSite(){
                    this.loader = true
                    console.log(this.form)
                    if(!this.prefix){
                        this.prefix=this.countries.country.country_prefix+"-EIDPOC- "
                    }
                    console.log(this.prefix)
                    axios_post("/site",{
                        ptid:this.prefix+this.form.ptid,
                        prefix:this.form.prefix,
                        suffix:this.form.ptid,
                        site_name:this.form.site_name,
                        location:this.form.location,
                        contact_person:this.form.contact_person,
                        email:this.form.email,
                        telephone:this.form.telephone,
                        region:this.form.region.id,
                        enrollment_status:this.form.enrollment_status,
                        facility_email:this.form.facility_email,
                        site_category:this.form.site_category,
                        comments:this.form.comments,
                        type_facility:this.form.type_facility,
                        head_of_facility:this.form.head_of_facility,
                        head_of_testingsite:this.form.head_of_testingsite,
                        phone_of_head:this.form.phone_of_head,
                        email_of_head:this.form.email_of_head,
                        email_of_facility_head:this.form.email_of_facility_head,
                        phone_of_facility_head:this.form.phone_of_facility_head
                    },(d)=>{
                        window.location.href='/sites'
                        console.log(d);
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    })

                },

            },

        };
</script>


<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>